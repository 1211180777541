import React, { useEffect, useState } from 'react'
import { Stack, Form } from 'react-bootstrap'
import './DashBoard.css'
import StatusBox from '../Common/StatusBox'
import TableData from '../Common/TableData'
import SideBar from '../Common/SideBar'
import Header from '../Common/Header'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllHouse, fetchHouse, getPropertiesApi, houseDataEmpty } from '../Redux/Action/PropertyAction'
import toast, { Toaster } from 'react-hot-toast'
import axios from 'axios'
// import { fetchSchedule } from '../Redux/Action/ScheduleAction'
import Spinner from '../Common/Spinner'


export default function DashBord() {
    let dispatch = useDispatch()
    let [loading,setloading] = useState(false)
    let HouseDetails = useSelector(state => state.HouseDetails)
    
    let [mostUsedAc, setMostUsedAc] = useState("Loading...")
    let [averageOnTime, setAverageOnTime] = useState("Loading...")
    let PropertyDetails = useSelector(state => state.PropertyDetails)
    let [selectedAppliance,setSelectedAppliance] = useState("")
    let [selectedProperty,setSelectedProperty] = useState("")    
    let [totalAppliances, setTotalAppliaces] = useState({ count: 0, countOn: 0, countOff: 0 })
    let [citys,setCitys] = useState([])
    let [rooms,setRooms] = useState([])
    let city =[]
    let [selectedRoom,setSelectedRoom] = useState("")
    let [selectedCity,setSelectedCity] = useState('Delhi')
    
    useEffect(() => {
        dispatch(houseDataEmpty([]))
        setTotalAppliaces({ count: 0, countOn: 0, countOff: 0 })
        setMostUsedAc('Loading...')
        setAverageOnTime('Loading...')
        fetchAllHouse(dispatch)
    }, [])
  
    useEffect(()=>{
        if(HouseDetails.length===0){
            setloading(true)
        }
        
        if(HouseDetails.length===0) return
        if(HouseDetails.length!==0){
            setloading(false)
        let count = 0
        let countOn = 0;
        let countOff = 0;
        let devices = [];
        let roomName=[]
        for(let house of HouseDetails){
        for (let room of house.rooms) {
            if(!roomName.includes(room.roomName) && room.devices.length!==0){
                roomName.push(room.roomName)
            }
                    for (let device of room.devices) {
                        
                        
                        for (let swit of device.switches) {
                            
                            if(selectedAppliance!=="" && swit.switchName.includes(selectedAppliance)){
                                devices.push(device.deviceId)
                            if (swit.switchState === "0") {
                                countOff += 1
                                count +=1
                            }
                            if (swit.switchState !== "0") {
                                countOn += 1
                                count +=1
                            }}else if(selectedAppliance===""){
                                devices.push(device.deviceId)
                                if (swit.switchState === "0") {
                                    countOff += 1
                                    count +=1
                                }
                                if (swit.switchState !== "0") {
                                    countOn += 1
                                    count +=1
                                }
                            }
                        }
                    }
                }
            }
        setRooms(roomName)
            setTotalAppliaces({ count: count, countOn: countOn, countOff: countOff })
        
           
        }
    },[HouseDetails])

    useEffect(()=>{
        if(HouseDetails.length===0) return
        let count = 0
        let countOn = 0;
        let countOff = 0;
        let devices = [];
        
        for(let house of HouseDetails){
            for (let room of house.rooms) {
                        for (let device of room.devices) {
                            for (let swit of device.switches) {
                                if(selectedAppliance!=="" && swit.switchName.includes(selectedAppliance)){
                                devices.push(device.deviceId)
                                if (swit.switchState === "0") {
                                    countOff += 1
                                    count +=1
                                }
                                if (swit.switchState !== "0") {
                                    countOn += 1
                                    count +=1
                                }}else if(selectedAppliance===""){
                                    devices.push(device.deviceId)
                                    if (swit.switchState === "0") {
                                        countOff += 1
                                        count +=1
                                    }
                                    if (swit.switchState !== "0") {
                                        countOn += 1
                                        count +=1
                                    }
                                }
                            }
                        }
                    }
                }
                
                 setTotalAppliaces({ count: count, countOn: countOn, countOff: countOff })
    },[selectedAppliance])
  

    return (
        <div style={{ display: "flex" }}>
            <SideBar />
            <div>
                <Header />
                <div
                    style={{ width: "95vw", height: "90vh", overflow: "scroll", backgroundColor: "#F9F9FB" }}
                    className='dashboard'
                >
                    <Stack direction="horizontal" gap={3} style={{ paddingLeft: "30px", paddingTop: "30px", backgroundColor: "#FFFFFF" }}>
                      
                        
                       
                        <Form.Group className="mb-3" style={{
                            width: "18%",
                            fontFamily: 'Manrope',
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12.9008px",
                            lineHeight: "19px",
                            color: "#000000",
                            textAlign:"left"
                        }}>
                        <Form.Label>Area</Form.Label>
                        <Form.Select
                          style={{
                            fontFamily: 'Manrope',
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12.9008px",
                            lineHeight: "19px",
                            color: "#000000",
                            backgroundColor: "#F9F9FB"
                        }}
                          onChange={(e)=>{
                              setSelectedRoom(e.target.value)
                        }}  
                        >
                        <option key={"All"} value={""}>{"All"}</option>

                            {
                                rooms.length !== 0 ?
                                    rooms.map((room) => {
                                        return (
                                            <option key={room} value={room}>{room}</option>
                                        )
                                    }) : null
                            }
                        </Form.Select>
                        </Form.Group>
                    
                        <div className='roomNumber mt-2'>
                            <span className='roomTotalHeader'>Total {selectedAppliance===""?"Rooms":selectedAppliance}'s </span><span className='roomTotalNumber'>{totalAppliances.count}</span>
                        </div>

                    </Stack>
                    <Stack direction="horizontal" gap={3} className='pt-3' style={{ paddingLeft: "30px", backgroundColor: "#FFFFFF" }}>
                        <StatusBox backgroundColor={"#FFE2E6"} status={`${selectedAppliance===""?"Appliances":selectedAppliance}'s On `} number={totalAppliances.countOn} circleColor={"#FA5A7C"} />
                        <StatusBox backgroundColor={"#FFF4DE"} status={`${selectedAppliance===""?"Appliances":selectedAppliance}'s Off`} number={totalAppliances.countOff} circleColor={"#FE947A"} />
                    </Stack>
                    {
                         loading===true?<Spinner/>:<TableData  citys={citys} selectedCity={selectedCity} selectedAppliance={selectedAppliance} selectedRoom={selectedRoom} selectedProperty={selectedProperty} city={city}/>
                    }
                </div>
            </div>
            <Toaster position="top-center" />
        </div>

    )
} 
